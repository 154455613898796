//fonts
@font-face
    font-family: 'Helvetica Neue'
    src: url('../fonts/HelveticaNeue-Italic.woff2') format('woff2'), url('../fonts/HelveticaNeue-Italic.woff') format('woff')
    font-weight: normal
    font-style: italic

@font-face
    font-family: 'Helvetica Neue'
    src: url('../fonts/HelveticaNeue-Bold.woff2') format('woff2'), url('../fonts/HelveticaNeue-Bold.woff') format('woff')
    font-weight: bold
    font-style: normal

@font-face
    font-family: 'Helvetica Neue'
    src: url('../fonts/HelveticaNeue-Light.woff2') format('woff2'), url('../fonts/HelveticaNeue-Light.woff') format('woff')
    font-weight: 300
    font-style: normal

@font-face
    font-family: 'Helvetica Neue'
    src: url('../fonts/HelveticaNeue-Medium.woff2') format('woff2'), url('../fonts/HelveticaNeue-Medium.woff') format('woff')
    font-weight: 500
    font-style: normal

@font-face
    font-family: 'Helvetica Neue'
    src: url('../fonts/HelveticaNeue-LightItalic.woff2') format('woff2'), url('../fonts/HelveticaNeue-LightItalic.woff') format('woff')
    font-weight: 300
    font-style: italic

@font-face
    font-family: 'Helvetica Neue'
    src: url('../fonts/HelveticaNeue.woff2') format('woff2'), url('../fonts/HelveticaNeue.woff') format('woff')
    font-weight: normal
    font-style: normal

$green: #70d44b
$gray: #cdd4df

*
    margin: 0
    padding: 0
    outline: 0 none
    box-sizing: border-box
    font-family: 'Helvetica Neue'

html,
body
    min-height: 100%
    height: 100%

a
    text-decoration: none
    color: #000
    font-weight: bold

input[type="text"],
input[type="date"],
select
    border: 2px solid $gray !important
    padding: 10px
    height: 40px
    &:focus,
    &:active
        border-color: $green

input
    &.search
        background: url(../icons/icons-search.svg) no-repeat scroll 96% center #fff
        background-size: 20px auto
        padding-right: 20px

ul
    list-style: none

body
    font-size: 16px
    font-family: sans-serif

.button
    display: inline-block
    //padding: 10px 15px
    font-size: 14px
    background: #999
    color: #fff
    border-radius: 5px
    border: none
    &:hover
        cursor: pointer

.off
    display: none !important
.main

    width: 100%
    background: #f9f9f9
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    //min-height: 100%

    .wrapper
        max-width: 1344px
        width: 100%
        margin: 0 auto

    .nav

        width: 100%
        display: block
        border-bottom: 1px solid $gray
        box-shadow: 0 0 10px 1px rgba(0,0,0,0.05)
        background: #fff
        .wrapper
            display: flex
            width: 100%
            padding: 20px
            flex-direction: row
            justify-content: space-between
            align-items: center
        .logo
            background: url(../icons/logo-full-green-horizontal.svg) no-repeat scroll center center transparent
            width: 95px
            height: 32px
            //filter: invert(100%)
            display: block
            font-size: 0
        .main-nav
            //margin-top: 41px
            display: flex
            flex-direction: row
            gap: 0 40px
            margin-top: -20px
            margin-bottom: -20px
            li
                .button
                    width: 125px
                    height: 25px
                    color: white
                    backgroundColor: #70d44b
                    borderRadius: 5px
                    fontWeight: bold
                    border: none
                    cursor: pointer
                a
                    display: flex
                    align-items: center
                    gap: 0 5px
                    padding: 24px 0 25px
                    font-size: 17px
                    font-weight: 500
                    line-height: 100%
                    border-bottom: 3px solid transparent
                    &.active
                        color: $green
                        border-bottom-color: $green
                        small
                            background: transparent
                            svg
                                path
                                    fill: $green
                    small
                        display: block
                        width: 20px
                        height: 20px
                        background: transparent
                        border-radius: 100%
                &:hover
                    opacity: 0.5

                // &.dashboard a small
                //     background: url(../imgs/icons-dashboard.svg) no-repeat scroll center center transparent
                //     background-size: contain
                // &.campaigns a small
                //     background: url(../imgs/icons-campaigns.svg) no-repeat scroll center center transparent
                //     background-size: contain
                // &.payments a small
                //     background: url(../imgs/icons-payments.svg) no-repeat scroll center center transparent
                //     background-size: contain
                // &.account a small
                //     background: url(../imgs/icons-account.svg) no-repeat scroll center center transparent
                //     background-size: contain

                &.on
                    background-color: #fff
                    a
                        color: $green
                        border-bottom-color: $green
                        small
                            background: transparent
                            svg
                                path
                                    fill: $green
                    &:hover
                        opacity: 1

        .user-nav
            //width: 100%
            display: flex
            justify-content: flex-end
            align-items: center
            gap: 0 10px
            position: relative
            .welcome-user
                strong
                    color: $green
            .notifications
                display: flex
                flex-direction: column
                align-items: flex-start
                justify-content: flex-end
                width: 30px
                height: 30px
                display: none
                // background: url(../imgs/icons-notifications.svg) no-repeat scroll center center transparent
                // background-size: contain
                // border-radius: 100%
                font-size: 0
                position: relative

                span
                    display: block
                    width: 27px
                    height: 27px
                    svg
                        width: 100%
                        height: 100%
                        display: block

                small
                    background: $green
                    color: #fff
                    position: absolute
                    top: -5px
                    right: -5px
                    display: flex
                    justify-content: center
                    align-items: center
                    font-size: 10px
                    font-weight: bold
                    border-radius: 100%
                    line-height: 110%
                    width: 20px
                    height: 20px
                    z-index: 9999
            .avatar
                display: flex
                width: 30px
                justify-content: center
                height: 30px
                align-items: center
                background: #70d44b
                background-size: contain
                border-radius: 100%
                line-height: 100%
                overflow: hidden
                font-weight: 500
                color: #fff
                &:hover
                    background: #333
                    cursor: pointer
            .nav-account
                display: none
                flex-direction: column
                position: absolute
                top: 35px
                background: #fff
                width: 125px
                border: 1px solid #ccc
                &.on
                    display: flex
                li
                    a
                        padding: 10px 5px
                        display: block
                        text-align: center

    .content
        width: 100%
        display: flex
        flex-direction: column
        min-height: calc(100vh - 73px)
        gap: 20px 0
        overflow: hidden
        .wrapper
            padding: 20px
        .content-header
            width: 100%
            display: flex
            flex-direction: column
            justify-content: flex-start
            align-items: flex-start
            gap: 20px 0
            //padding: 20px

            .section-title
                width: 50%
                h2
                    width: 100%
                    display: flex
                    align-items: center
                    font-size: 20px
                    font-weight: bold
            .secondary-nav
                display: flex
                align-items: center
                justify-content: space-between
                width: 100%
                border-bottom: 1px solid #ccc
                margin-bottom: 20px
                ul
                    display: flex
                    gap: 0

                    &.nav-actions
                        gap: 0 20px
                        .icon-left
                            a
                                display: flex
                                gap: 0 10px
                                align-items: center
                                small
                                    display: block
                                    width: 20px
                                    height: 20px
                                    //background: #ccc
                                svg
                                    display: block
                                    width: 100%
                                    height: 100%
                                    path
                                        fill: #999
                            &.return a small
                                height: 15px
                    &.nav-tabs
                        gap: 0
                        li
                            a
                                display: block
                                height: 100%
                                padding: 10px
                                border-right: 1px solid #ccc

                                &.active
                                    color: $green

                            &:last-child a
                                border-right: none
                            &:first-child a
                                padding-left: 0

                    li
                        &.on
                        a
                            color: #999
                        a
                            display: block
                            padding: 5px 0
                            padding: 10px 0

        .content-body
            width: 100%
            display: flex
            flex-direction: column
            justify-content: flex-start
            align-items: flex-start

            flex-wrap: wrap

            //GENERICS

            .campaign-content
                display: flex
                flex-direction: column
                gap: 20px
                width: 100%

                &.general
                    flex-direction: row
                    .campaign-general,
                    .campaign-segmentation
                        width: calc(50% - (20px / 2))

            .campaigns-search
                grid-column: 1 / span 3
                gap: 0 20px
                display: flex
                flex-wrap: wrap
                h3
                    width: 100%
                    display: block
                    margin-bottom: 20px
                .search-filters
                    display: flex
                    align-items: flex-start
                    justify-content: space-between
                    width: 100%
                    gap: 0 20px

                    // ::-webkit-datetime-edit
                    // ::-webkit-datetime-edit-fields-wrapper
                    // ::-webkit-datetime-edit-text
                    // ::-webkit-datetime-edit-month-field
                    // ::-webkit-datetime-edit-day-field
                    // ::-webkit-datetime-edit-year-field
                    // ::-webkit-inner-spin-button

                .search-filter
                    width: 100%
                    display: flex
                    flex-direction: column
                    align-items: flex-start
                    justify-content: space-between
                    position: relative
                    input,select
                        width: 100%
                        gap: 10px 0
                    input[type="date"]
                        cursor: pointer

                    label
                        font-weight: bold
                        font-size: 12px
                        text-transform: uppercase
                        margin-bottom: 5px
                    .fake-select
                        flex-direction: column
                        justify-content: flex-start
                        align-items: flex-start
                        position: absolute
                        display: none
                        top: 60px
                        left: 0
                        right: 0
                        background: #fff

                        li
                            width: 100%
                            display: flex
                            align-items: center
                            justify-content: flex-start
                            border: 1px solid #ccc
                            padding: 8px 10px
                            &:hover
                                background: #f9f9f9
                                cursor: pointer
                        // &:after
                        //     border: 3px solid black
                        //     border-left-color: transparent
                        //     display: block
                        //     width: 1px
                        //     height: 1px
                        //     position: absolute
                    .search
                        &:focus + .fake-select
                            display: block

            .campaigns-summary-list
                grid-column: 1 / span 3

                table
                    width: 100%
                    thead
                        tr
                            th
                                font-size: 16px
                    th,
                    td
                        text-align: left
                        padding: 5px

                        a
                            display: inline-block
                            padding: 5px 10px
                            font-size: 12px
                            background: $green
                            color: #fff
                            text-align: center
                            width: 100%
                            border-radius: 5px
                            &:hover
                                background: #66c145
                    tbody
                        tr
                            &:nth-of-type(odd)
                                background: #f9f9f9
                            td
                                font-size: 14px
                            &:hover
                                td
                                    background: #eee

            .graph
                img
                    //max-width: 200px
                ul
                    margin-top: 20px
                    display: flex
                    flex-direction: column
                    gap: 10px 0
                    li
                        display: flex
                        gap: 0 10px
                        strong
                            display: block
                            width: 20px
                            height: 20px
                            flex-shrink: 0
                            background: #ccc
            .footer-action
                width: 100%
                display: flex
                justify-content: space-between
                align-items: center
                .pagination
                    display: flex
                    align-items: center
                    justify-content: flex-start
                    gap: 0 10px
                    li
                        display: block
                        a
                            display: flex
                            justify-content: center
                            align-items: center
                            font-size: 16px
                            font-weight: bold
                            padding: 5px 10px
                            border: 1px solid #ccc
                            background: #fff
                            &.current
                                background: $green
                                color: #fff
                                border-color: rgba(0,0,0,0.15)
            .download

                background-size: 20px
                display: flex
                justify-content: center
                align-items: center
                gap: 0 5px
                width: max-content
                &:hover
                    background: $green
                    color: #fff
                small
                    display: block
                    width: 20px
                    height: 20px
                    svg
                        width: 100%
                        height: 100%
                        display: block
                        path
                            fill: #fff
            .button
                &.more
                    display: inline-block
                    padding: 15px 20px
                    background: #000
                    color: #fff

            //SPECIFICS

            .dashboard
                display: grid
                flex-direction: column
                grid-template-columns: calc(33.3% - (40px / 3)) calc(33.3% - (40px / 3)) calc(33.3% - (40px / 3))
                grid-template-rows: auto
                grid-gap: 20px
                flex-wrap: wrap
                justify-content: flex-start
                align-items: flex-start
                width: 100%
                //padding: 20px

                .info-cliente
                    grid-column: 1 / span 1
                    grid-row: 1 / span 2

            .box
                padding: 20px
                border: 1px solid #ccc
                background: #fff
                flex-grow: 0
                flex-shrink: 0
                height: 100%
                label
                    display: block
                    font-weight: bold
                    font-size: 14px
                    text-transform: uppercase
                    margin-bottom: 20px
                    color: #999
                .text-emphasis
                    display: flex
                    gap: 0 10px
                    align-items: center
                    width: 100%
                    strong
                        display: inline-flex
                        font-size: 25px
                    small
                        display: inline-flex
                        font-size: 18px
                .data-rows
                    display: flex
                    flex-direction: column
                    justify-content: flex-start
                    align-items: flex-start

                    li
                        display: flex
                        align-items: center
                        width: 100%
                        justify-content: space-between
                        border-bottom: 1px solid #ddd
                        padding: 5px 0
                        &:last-child
                            border-bottom: none
                            padding-bottom: 0
                .switch
                    display: flex
                    flex-wrap: wrap
                    flex-direction: column
                    gap: 5px 0
                    margin: 0
                    label
                        margin: 0
                    .options
                        justify-content: flex-start
                        align-items: center
                        display: flex
                        flex-direction: row
                        border: 2px solid $green
                        width: max-content
                        height: 32px
                        border-radius: 5px
                        li
                            display: block
                            padding: 4px 10px
                            border-right: 2px solid $green
                            //height: 30px
                            font-weight: 500
                            color: #999
                            &:hover
                                cursor: pointer
                            &:last-child
                                border-right: none

                            &.on
                                background: $green
                                color: #fff
                .legends
                    padding-top: 20px

                    list-style: none
                    display: flex
                    flex-direction: column
                    gap: 5px 0
                    justify-content: flex-start
                    align-items: flex-start
                    &.col2
                        gap: 5px 10px
                        flex-direction: row
                        flex-wrap: wrap
                        li
                            width: calc(50% - 5px)
                    li
                        display: flex
                        flex-direction: row
                        align-items: flex-start
                        justify-content: flex-start
                        gap: 0 5px
                    small
                        display: block
                        width: 18px
                        height: 18px
                    strong
                        font-size: 14px
                        font-weight: 500
                        color: #999

                .custom-funnel-chart
                    display: grid
                    grid-template-columns: 1fr 70px
                    grid-template-rows: auto
                    gap: 5px 10px
                    overflow: hidden
                    .funnel-step
                        //background: #ccc
                        width: 100%
                        position: relative
                        color: #000
                        padding: 0
                        display: flex
                        justify-content: center
                        align-items: center
                        &:nth-of-type(1)
                            &:before
                                width: 175px
                                border-top-color: #fc7391
                            small
                                width: min-content
                                background: #fc7391
                            //border: 2px solid #fc7391
                            // border-left: 50px solid transparent
                            // border-right: 50px solid transparent
                        &:nth-of-type(2)
                            &:before
                                border-top-color: #36a2ec
                                width: 130px
                            small
                                background: #36a2ec
                            // border-left: 30px solid transparent
                            // border-right: 30px solid transparent
                        &:nth-of-type(3)
                            &:before
                                width: 85px
                                border-top-color: #9966ff
                            small
                                background: #9966ff
                            // border-left: 15px solid transparent
                            // border-right: 15px solid transparent
                        &:nth-of-type(4)
                            &:before
                                width: 0
                                border-top: 40px solid #4bc0c0
                                // border-left: 45px solid transparent
                                // border-right: 45px solid transparent
                                border-bottom: none
                            small
                                background: #4bc0c0
                                // padding-bottom: 15px
                        &:before
                            z-index: 0
                            box-sizing: border-box
                            // top: 0
                            // right: 0
                            // left: 0
                            display: block
                            content: ""
                            //width: 100%
                            height: 0
                            margin: 0 auto
                            border-left: 20px solid transparent
                            border-right: 20px solid transparent
                            border-top: 40px solid #ccc

                        small
                            z-index: 1
                            position: absolute
                            left: auto
                            right: auto
                            text-align: center
                            color: #fff
                            font-weight: 500
                            //padding-bottom: 10px
                    .funnel-label
                        height: 100%
                        display: flex
                        justify-content: flex-start
                        align-items: center
                        text-align: left
                        font-size: 14px
                        font-weight: 500
                        color: #999
                .custom-age-chart
                    display: grid
                    grid-template-columns: 1fr 40px
                    grid-template-rows: auto
                    gap: 5px 10px
                    overflow: hidden
                    width: 100%
                    .age-step
                        //background: #ccc
                        width: 100%
                        position: relative
                        color: #000
                        padding: 0
                        display: flex
                        justify-content: center
                        align-items: center
                        &.headers
                            font-size: 14px
                            font-weight: 500
                            color: #999
                        &:nth-of-type(1)
                            strong
                                background: rgba(112,212,75,1)
                        &:nth-of-type(2)
                            strong
                                background: rgba(112,212,75,0.875)
                        &:nth-of-type(3)
                            strong
                                background: rgba(112,212,75,0.75)
                        &:nth-of-type(4)
                            strong
                                background: rgba(112,212,75,0.625)
                        &:nth-of-type(5)
                            strong
                                background: rgba(112,212,75,0.5)
                        &:nth-of-type(6)
                            strong
                                background: rgba(112,212,75,0.375)
                        &:nth-of-type(7)
                            strong
                                background: rgba(112,212,75,0.25)
                        strong
                            z-index: 0
                            box-sizing: border-box
                            // top: 0
                            // right: 0
                            // left: 0
                            display: block
                            content: ""
                            width: 100%
                            height: 20px
                            background: #ccc
                            margin: 0 auto

                        small
                            z-index: 1
                            position: absolute
                            left: auto
                            right: auto
                            text-align: center
                            color: #333
                            font-weight: 500
                            //background: #fff
                            //padding-bottom: 10px
                    .age-label
                        height: 100%
                        display: flex
                        justify-content: flex-start
                        align-items: center
                        text-align: left
                        font-weight: 500
                        font-size: 14px
                        color: #999
                .custom-age-gender-chart
                    display: flex
                    flex-direction: column
                    gap: 5px 0
                    overflow: hidden
                    width: 100%
                    .age-step
                        //background: #ccc
                        width: 100%
                        position: relative
                        color: #000
                        padding: 0
                        display: grid
                        grid-template-columns: 50px 1fr 1fr 50px
                        grid-template-rows: auto
                        justify-content: center
                        align-items: center
                        gap: 0 5px

                        &.headers
                            .bar
                                height: auto

                        .women-label
                            text-align: left
                            justify-content: flex-start
                        .men-label
                            text-align: right
                            justify-content: flex-end

                        strong:first-child
                            margin-left: 0

                        &:nth-of-type(2)
                            .bar
                                &.women
                                    span
                                        background: rgba(112,212,75,1)
                                &.men
                                    span
                                        background: rgba(112,212,75,1)
                        &:nth-of-type(3)
                            .bar
                                &.women
                                    span
                                        background: rgba(112,212,75,1)
                                &.men
                                    span
                                        background: rgba(112,212,75,1)
                        &:nth-of-type(4)
                            .bar span
                                background: rgba(112,212,75,0.75)
                        &:nth-of-type(5)
                            .bar span
                                background: rgba(112,212,75,0.625)
                        &:nth-of-type(6)
                            .bar span
                                background: rgba(112,212,75,0.5)
                        &:nth-of-type(7)
                            .bar span
                                background: rgba(112,212,75,0.375)
                        &:nth-of-type(8)
                            .bar span
                                background: rgba(112,212,75,0.25)
                        .bar
                            display: flex
                            align-items: center
                            width: 100%
                            height: 20px
                            font-size: 14px
                            font-weight: 500
                            color: #999

                            &.women
                                justify-content: flex-end
                                text-align: right
                            &.men
                                justify-content: flex-start
                                text-align: left

                            span
                                display: block
                                height: 100%

                        small
                            z-index: 1
                            position: absolute
                            left: auto
                            right: auto
                            text-align: center
                            color: #333
                            font-weight: 500
                            //background: #fff
                            //padding-bottom: 10px
                        .men-label,
                        .women-label
                            height: 100%
                            display: flex

                            align-items: center
                            font-size: 14px
                            font-weight: 500
                            color: #999
                .custom-campaigns-views-duration-chart
                    width: 100%
                    //height: 100%
                    display: grid
                    grid-template-columns: 100px 1fr
                    grid-template-rows: 1fr 25px 25px auto

                    .y-axis
                        grid-column: 1 / span 2

                        height: 300px
                        display: flex
                        flex-direction: column
                        justify-content: space-between

                        &.views
                            grid-row: 1 / span 1
                            span
                                width: 100%
                                display: flex
                                flex-direction: column
                                justify-content: flex-start
                                align-items: flex-start
                                height: 20%
                                position: relative
                                border-top: 1px solid #ccc
                                &.min
                                    border-bottom: 1px solid #ccc
                                    justify-content: space-between
                                    label
                                        //background: pink
                                        &:nth-child(2)
                                            margin: 0 0 -8px 0
                            label
                                display: inline-block
                                background: #fff
                                margin: -8px 0 0 0
                                z-index: 1
                                padding: 0 5px
                        &.campaigns
                            grid-row: 4 / span 1
                            justify-content: flex-start
                            gap: 0
                            height: auto
                            .divider
                                label
                                    margin: 0
                        &.weeks
                            grid-column: 1 / span 1
                            grid-row: 3 / span 1
                    .x-axis

                        width: 100%
                        display: flex
                        flex-direction: row

                        &.months
                            grid-column: 2 / span 1
                            grid-row: 2 / span 1
                            gap: 0 10px

                            .divider
                                padding: 5px
                                width: calc(100% / 12)
                        &.weeks
                            grid-column: 2 / span 1
                            grid-row: 3 / span 1
                            .divider
                                width: calc(100% / 52)
                                padding: 0
                                font-weight: 500

                        .divider

                            text-align: center
                            display: block
                            padding: 5px
                            box-sizing: border-box
                            font-weight: bold
                            font-size: 12px

                    .graph-area
                        grid-column: 2 / span 1
                        position: relative

                        &.bars
                            grid-row: 1 / span 1
                        .bars
                            position: absolute
                            left: 0
                            bottom: 0
                            right: 0
                            height: 100%
                            //background: pink
                            width: 100%
                            display: flex
                            flex-direction: row
                            align-items: flex-end
                            justify-content: flex-start
                            //gap: 0 10px

                            li
                                display: flex
                                flex-direction: column
                                justify-content: flex-end
                                width: calc(100% / 12)
                                padding: 0
                                height: 100%
                                box-sizing: border-box
                                border-left: 1px solid #ccc
                                &:last-of-type
                                    border-right: 1px solid #ccc

                                label
                                    margin: 0
                                    display: block
                                    text-align: center
                                    //background: #fff

                                .bar
                                    width: 100%
                                    display: block
                                    background: #cccccc54
                                    border-top: 1px solid #ccc

                        .minibars
                            position: absolute
                            width: 100%
                            inset: 0
                            display: flex
                            flex-direction: row
                            justify-content: flex-start
                            align-items: flex-end
                            li
                                width: calc( 100% / 52)
                                display: flex
                                flex-direction: column
                                justify-content: flex-start
                                align-items: center
                                height: 100%
                                flex-shrink: 0
                                flex-grow: 0
                                background: pink
                                border: 1px solid #ccc
                                border-radius: 5px 5px 0 0
                                background: #fff
                                position: relative

                                label

                                    display: none
                                    top: 0
                                    left: 50%
                                    transform: translate(-50%,-150%)
                                    width: max-content
                                    position: absolute
                                    background: #fff
                                    padding: 2px 5px
                                    border: 1px solid #ccc
                                    z-index: 9999

                                &:hover
                                    cursor: pointer
                                    border: 1px solid #000
                                    background: #000
                                    label
                                        display: flex

                        &.lines
                            //display: none
                            grid-row: 4 / span 1
                            .lines
                                display: grid
                                grid-template-columns: repeat(52,1fr)
                                grid-template-rows: auto
                                width: 100%
                                li

                                    display: flex
                                    justify-content: center
                                    align-items: center
                                    content: ""
                                    width: 100%
                                    height: 17px
                                    .line
                                        display: flex
                                        justify-content: space-between
                                        align-items: center
                                        background: #ccc
                                        height: 5px
                                        border-radius: 5px
                                        width: 100%
                                        .date
                                            display: block
                                            width: 10px
                                            height: 10px
                                            position: relative
                                            background-color: inherit
                                            border-radius: 100%

                                            label
                                                display: none
                                                position: absolute
                                                top: 0
                                                left: 50%
                                                transform: translate(-50%, -150%)
                                                width: max-content
                                                background: #fff
                                                padding: 2px 5px
                                                border: 1px solid #ccc
                                                z-index: 9999

                                            &:hover
                                                cursor: pointer
                                                label
                                                    display: block

                .user-summary,
                .payments-summary,
                .campaigns-summary
                    height: 100%
                    width: 100%

                &.campanas-meses-impactos
                    grid-column: 1 / span 3

            .campaigns
                //padding: 20px
                width: 100%
                gap: 20px 0
                display: flex
                flex-direction: column

                .box
                    padding: 20px
                    border: 1px solid #ccc
                    background: #fff
                    //border-radius: 5px
                    flex-grow: 0
                    flex-shrink: 0

            // .campaign
            //     //padding: 20px
            //     width: 100%
            //     gap: 20px 0
            //     display: flex
            //     flex-direction: column

            .campaign-content

                gap: 20px
                display: flex
                flex-wrap: wrap

                .campaign-general,
                .campaign-segmentation,
                .campaign-preview
                    // padding: 20px
                    // border: 1px solid #ccc
                    //border-radius: 5px
                    // background: #fff
                    // flex-grow: 0
                    // flex-shrink: 0

                .campaign-nav
                    width: 100%
                    .tabs
                        display: flex
                        justify-content: flex-start
                        gap: 0 10px
                        li
                            border-radius: 5px
                            &.on
                                background: $green
                                a
                                    color: #fff
                                &:hover
                                    background: $green
                            &:hover
                                background: #eee
                            a
                                padding: 8px 10px
                                display: block

                .campaign-general,
                .campaign-segmentation
                    width: calc(50% - 10px)
                .campaign-general
                    .campaign-information
                        ul
                            display: flex
                            flex-direction: column
                            //gap: 10px 0
                            li
                                padding: 5px 0 10px
                                border-bottom: 1px solid #ccc
                                margin-bottom: 5px
                                &:last-child
                                    border-bottom: none
                                    margin-bottom: 0
                                    padding-bottom: 0
                .campaign-segmentation
                    display: flex
                    flex-direction: column
                    gap: 20px 0

                    // &:last-of-type
                    //     border-bottom: none
                    //     padding-bottom: 0
                    h3
                        margin-bottom: 5px
                    p
                        strong
                            color: $green

                .campaign-preview
                    width: 100%
                   
                    #display: flex 
                    flex-wrap: wrap
                    justify-content: space-between
                    gap: 20px
                    .screen
                        display: flex
                        flex-direction: column
                        flex-shrink: 0
                        .screen-wrapper
                            display: flex
                            flex-direction: column
                            gap: 10px 0

                            label
                                width: 100%
                                font-weight: bold
                                margin: 0
                            iframe
                                width: 320px
                                height: 565px
                                border: 2px solid #ccc
                    .test-url
                        width: 100%
                        a
                            //color: $green

                .campaign-analytics
                    display: grid
                    grid-template-columns: repeat(3,calc(33.3% - 13.33px))
                    grid-template-rows: auto
                    grid-gap: 20px
                    gap: 20px
                    flex-wrap: wrap
                    width: 100%

                    .campaign-days,
                    .campaign-impressions,
                    .campaign-users-impacts

                    .pseudo-graph
                        display: flex
                        flex-direction: row
                        justify-content: space-between
                        flex-wrap: wrap
                        .square
                            width: 100%
                            margin-bottom: 10px
                            small
                                display: flex
                                justify-content: center
                                align-items: center
                                width: 50px
                                height: 50px
                                background: #ccc
                            em
                                display: block
                                background: #fff
                                width: 100%
                                font-style: normal
                                padding-top: 5px
                        .number,
                        .total
                            margin-bottom: 10px
                            display: block
                            width: 100%
                            background: #f9f9f9

                            small
                                display: block
                                background: #ccc
                                padding: 5px
                                height: 27px
                            em
                                display: block
                                background: #fff
                                width: 100%
                                font-style: normal
                                padding-top: 5px
                        small
                            font-size: 14px

                    .campaign-total-impressions
                        display: flex
                        position: relative
                        gap: 0 20px
                        width: 100%
                        flex-wrap: wrap
                        grid-column: 1 / span 3

                        .screen
                            display: flex
                            margin-bottom: 20px
                            flex-direction: column
                            gap: 10px 0
                            width: calc(33.33% - (40px / 3))
                            label
                                width: 100%
                                font-weight: 600
                                display: block
                                font-size: 18px
                        .print-logo
                            width: 200px
                            height: 100px
                            position: absolute
                            display: block
                            bottom: 20px
                            right: 20px
        

                    .campaign-funnel
                        display: flex
                        gap: 0 20px
                        width: 100%
                        grid-column: 1 / span 3
                        .screen
                            display: flex
                            flex-direction: column
                            gap: 10px 0
                            width: calc(33.33% - (40px / 3))

                            label
                                width: 100%
                                font-weight: 600
                                display: block
                                font-size: 18px
                                //text-align: center

                    .campaign-extras
                        display: flex
                        gap: 0 20px
                        width: 100%
                        grid-column: 1 / span 3
                        .screen
                            display: flex
                            flex-direction: column
                            gap: 10px 0
                            width: calc(33.33% - (40px / 3))

                            label
                                width: 100%
                                font-weight: 600
                                display: block
                                font-size: 18px

                .table-graph
                    tr
                        th,
                        td
                            text-align: left
                            span
                                display: inline-block
                                height: 17px
                                background: #ccc
                            small
                                width: 50px
                                display: inline-block

                .campaign-registries
                    width: 100%
                    display: flex
                    flex-direction: column
                    gap: 20px
                    .box
                        height: auto

                    .campaign-filter-summary

                        display: flex
                        gap: 20px
                        padding: 0
                        border: 0
                        background: transparent

                        .filter-summary
                            width: max-content
                            display: flex
                            flex-direction: column
                            align-items: center
                            gap: 10px 0

                            flex-grow: 0
                            flex-shrink: 0

                            &.c1
                                width: 150px
                            &.c2
                                width: 200px
                            &.c5
                                width: calc(100% - (150px * 3) - 200px - (20px * 4))
                            label
                                text-align: center
                            .text-emphasis
                                flex-direction: column
                                width: unset

                            &.age-ranges
                                .text-emphasis
                                    gap: 5px 0
                                    small
                                        //font-size: 14px
                                    strong
                                        font-size: 20px
                            ul
                                display: flex
                                gap: 0 20px
                                li
                                    display: flex
                                    #flex-direction: column
                                    align-items: center

            .account-details
                
                .account-form
                    width: 100%
                    form
                        display: flex
                        flex-direction: column
                        gap: 10px 0
                        .row
                            width: 100%
                            display: flex
                            flex-direction: row
                            align-items: center
                            justify-contents: flex-start
                            gap: 20px
                            border-bottom: 1px solid #ccc
                            padding-bottom: 10px
                            &:last-of-type
                                border-bottom: none
                                padding-bottom: 0
                            label
                                width: 200px
                                font-weight: bold
                                margin-bottom: 0
                            input[type="text"]
                                width: calc(100% - 220px)
                                border: 2px solid #ccc
                            input[type="submit"]
                                color: #fff
                                background: $green
                                border: none
                                padding: 15px 20px
                                font-size: 15px
                                font-weight: bold
                                margin-top: 20px

//LOGIN

.login
    width: 100%
    height: 100%
    position: fixed
    inset: 0
    display: flex
    justify-content: center
    align-items: center
    background: #f2f2f2

    .wrapper
        padding: 40px
        border: 1px solid #ccc
        display: flex
        flex-direction: column
        align-items: flex-start
        border-radius: 5px
        gap: 0
        width: auto
        background: #fff
        box-shadow: 0 0 1px 0px rgba(0,0,0,0.15)

        .logo
            background: url(../icons/logo-full-green-horizontal.svg) no-repeat scroll center center transparent
            width: 190px
            height: 50px
            //filter: invert(100%)
            display: block
            font-size: 0
            margin-bottom: 20px

        h1
            //text-transform: uppercase
            font-size: 18px
            color: #ccc
        h2
            margin-bottom: 20px
        form
            width: 100%
        .row
            width: 100%
            display: flex
            flex-direction: column
            margin-bottom: 20px
            &:last-of-type
                margin-bottom: 0
            label
                text-transform: uppercase
                font-size: 12px
                font-weight: bold
                color: #ccc
                margin-bottom: 2px
        input,
        button
            padding: 10px
            width: 100%
            border: 2px solid #ccc
            //letter-spacing: 1px
            border-radius: 5px
            &:hover
                border-color: #aaa
            &:focus
                border-color: $green
                background: #f9f9f9
                color: #000
        .submit
            border: none
            background: $green
            color: #fff
            font-weight: bold
            font-size: 22px
            padding: 20px 10px
            border-radius: 5px
            display: block
            box-sizing: border-box
            flex-shrink: 0
            letter-spacing: 0
            //margin-top: 20px
            &:hover
                background: #62af46
                cursor: pointer

.style-card
    height: 100%
    width: 100%
    // height: '100%'

    .style-title-card
        color: #999
        font-size: 14px
        margin-bottom: 15px

        @media only screen and (max-width: 768px)
            font-size: 12px

    .style-text-primary
        color: '#3636363'
        font-size: 24px
        font-weight: 600

        @media only screen and (max-width: 768px)
            font-size: 20px

    .style-text-secundary
        color: '#3636363'
        font-size: 18px
        font-weight: normal

        @media only screen and (max-width: 768px)
            font-size: 16px

.card-overflow
    overflow-x: auto

    .card-overflow-width
        min-width: 550px
        
.cursor-pointer
    cursor: pointer

.disabled
    opacity: 0.2
    cursor: none
    pointer-events: none