.pages{
    display: flex;
    padding: 20px 0;
    max-width: 90%;
    flex-wrap: wrap;
    list-style: none;
}

.numbers{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    width: 30px;
    padding: 5px 10px;
    border: 1px solid #dbdbdd;
    color: black;
    background-color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
.numbers:focus{
    color: white;
    background-color: #70d44b;
}